.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-top: 1em;
color: #a2a2a2;
text-decoration: underline;
width: fit-content;
margin-left: auto;
margin-right: auto;
cursor: pointer;
z-index: 999999;
}