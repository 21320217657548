.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
color: #000;
padding: 0.5em 0;
align-items: center;
justify-content: space-between;

& + & {
border-top: 1px solid #aaa;
}
}