.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: column;
align-content: center;
padding: 4.5em 2.5em;
background-color: #fefefe;
height: fit-content;
max-height: 66%;
border-radius: 4px;
overflow-y: auto;
}