.cls1 { /*!*/ }
.cls2 {
composes: cls1;

:global {
.ant-form-item-control-input-content,
.ant-form-item-control-input-content .ant-input-number-group-wrapper,
.ant-form-item-control-input-content .ant-input-number {
width: 100%;
}
}
}