.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: fixed;
top: 0;
left: 0;
height: 100vh;
width: 100vw;
display: flex;
align-items: center;
justify-content: center;
background-color: #000000d0;
z-index: 999;
}